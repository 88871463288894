import {isMobile} from "react-device-detect";
import {copyToClipboard} from "./utils";
import {useSearchParams} from "react-router-dom";

export function useDownload() {
  const [searchParams] = useSearchParams();

  const adjustId = searchParams.get("adjustId") || "1gdir1x2";
  const campaign = searchParams.get("campaign");
  const adgroup = searchParams.get("adgroup");
  const creative = searchParams.get("creative");
  const fbclid = searchParams.get("fbclid");
  const fb_pixel_id = searchParams.get("fb_dynamic_pixel");
  // const fb_access_token =
  //   "EAANJHpHnkhABO0gdx8URjY2jRbJOsDZCYZCLvGE2tKbzgZBTB177R4pYeeKrmY7vN4GbFXEUKF0lxLb8CsSvlBG5IZC7nggd6SiZAJaXnfTtpiBQCZBMB0xB21ZAICfUXPBzTSQMhAeJZBZCmD1iLit4NQmGHQB7gosBjTPp12xGD9UtHZAFlMiAirLYGyfEZC7Cgj1UAZDZD";

  const onDownload = () => {
    const inviteCode = searchParams.get("invite");

    console.log({adjustId, campaign, adgroup, creative, fbclid});

    if (inviteCode) {
      copyToClipboard(inviteCode as string);
    }

    if (!isMobile) {
      return "popup";
    } else {
      const downloadurl = "https://wgaym.rjziobgo.com/api/c/64gq4kuj";
      const encodedUrl = encodeURIComponent(downloadurl);
      if (fbclid) {
        const params = {
          campaign,
          adgroup,
          creative,
          fbclid,
          fb_pixel_id,
          // fb_access_token
        };

        const queryString = Object.keys(params)
          .filter(
            (key) =>
              params[key as keyof typeof params] !== null &&
              params[key as keyof typeof params] !== undefined
          )
          .map(
            (key) =>
              `${key}=${encodeURIComponent(
                params[key as keyof typeof params] as string
              )}`
          )
          .join("&");
        window.open(
          `https://app.adjust.com/${adjustId}?${queryString}&redirect=${encodedUrl}`,
          "_blank"
        );
      } else {
        window.open(downloadurl, "_blank");
      }
    }
  };

  return {onDownload};
}
